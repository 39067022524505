import { MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { type BrandActionNotification } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { XIcon } from '../icons/XIcon';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';

function BrandActionNotifcationView(
  props: BaseNotificationProps & {
    notification: BrandActionNotification;
  }
): JSX.Element {
  const metadata = props.notification.metadata;
  const brand = metadata.brand;
  const coverUrl =
    MediaUtils.PickMediaUrl(fromMediaDTO(brand.showcaseMedia), {
      priority: [MediaFormatVersion.SM],
    }) || placeholder;
  const notificationDataSource = useNotificationDataSource();

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  const handleClick = async () => {
    window.open(`/admin/brands/${brand.id}`, '_blank');
  };
  return (
    <div
      className='w-full flex items-center justify-center cursor-pointer group bg-secondary'
      onClick={handleClick}
    >
      <div className='w-26 h-15 bg-black'>
        <img
          className='w-full h-full object-cover'
          src={coverUrl}
          alt='cover'
        />
      </div>
      <div className='flex flex-col ml-3 w-65'>
        <div className='text-2xs text-[#C9C9C9]'>Created a Brand:</div>
        <div className='text-sm text-white truncate group-hover:underline'>
          {brand.name}
        </div>
      </div>
      <div className='cursor-pointer ml-auto mr-4' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
}

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default BrandActionNotifcationView;
